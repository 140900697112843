<template>
  <div class="users-setting">
    <div class="header-user">
      <div class="search">
        <span class="searchIcon" title="search">
          <font-awesome-icon icon="search"
        /></span>
        <input
          v-debounce:400="filter"
          class="input-search"
          type="text"
          v-model="full_name"
          placeholder="Rechercher un utilisateur "
        />
        <b-button
          size="sm"
          variant="success"
          v-b-modal.exportUserModal
          class="ml-2"
          v-if="currentUser && currentUser.isSuper === 1"
          @click="exportClickUser"
        >
          Export excel
        </b-button>
      </div>
      <div
        v-if="!initLoading && getUserLoading"
        class="three-dots-loading sortloading"
      >
        Chargement en cours
      </div>
      <div
        class="activer-desactiver-user"
        v-if="currentUser && currentUser.isSuper"
      >
        <b-button
          class="button-activer mr-3 border-show"
          variant="outline-secondary"
          @click="handlClickContactSecurite()"
          pill
          >Contacts de sécurité</b-button
        >
        <b-button
          class="button-activer"
          variant="outline-secondary"
          @click="handlClickActive(1)"
          pill
          :pressed="this.active === 1"
          >Activé</b-button
        >
        <b-button
          class="button-desactiver"
          variant="outline-secondary"
          @click="handlClickActive(0)"
          :pressed="this.active === 0"
          pill
          >Désactivé</b-button
        >
      </div>
    </div>
    <div v-if="initLoading" class="init-loading three-dots-loading">
      Chargement en cours
    </div>
    <div v-else class="content-tab-users">
      <b-table
        small
        show-empty
        id="my-table"
        class="table"
        :items="users"
        :fields="fields"
        :current-page="page"
        :per-page="0"
        sort-icon-left
        no-sort-reset
        :sort-by.sync="sort_by"
        :sort-desc.sync="sortDesc"
        no-local-sorting
        @sort-changed="sort"
        empty-text="Il n'y a aucun enregistrement à afficher"
        empty-filtered-text="Aucun enregistrement ne correspond à votre demande"
      >
        <template v-slot:cell(first_name)="data">
          <div
            class="font-div"
            :class="{
              'icon-exclamation':
                data.item.pipe_tech_deleted === 1 ||
                data.item.pipe_user_deleted === 1 ||
                data.item.pipe_user_active === 0
            }"
            :title="
              data.item.pipe_tech_deleted === 1
                ? 'Technicien supprimé de pipedrive'
                : data.item.pipe_user_active == 0
                ? 'Utilisateur pipedrive n\'est plus actif'
                : data.item.pipe_user_deleted === 1
                ? 'Utilisateur pipedrive est supprimé'
                : ''
            "
          >
            {{ data.item.full_name }}
          </div>
        </template>
        <template v-slot:cell(email)="data">
          <p class="font-p">{{ data.item.email }}</p>
        </template>
        <template v-slot:cell(type)="data">
          <p class="font-p">{{ data.item.type | typeFormat }}</p>
        </template>
        <template v-slot:cell(team_name)="data">
          <div v-if="data.item.team" class="font-div">
            <span>
              {{ data.item.team.data.name }}
            </span>
            <br />
            <strong class="equipe">
              ( {{ data.item.team.data.type | FormatType }} )
            </strong>
            <strong
              class="type"
              v-if="data.item.depot_id != null && data.item.team"
            >
              - ( {{ data.item.depot.data.name }} )
            </strong>
          </div>
        </template>
        <template v-slot:cell(created_at)="data">
          <p class="font-p">
            {{ data.item ? data.item.created_at.split(',')[0] : '' }}
          </p>
        </template>
        <template v-slot:cell(actions)="data">
          <div class="actions">
            <div>
              <a
                href="#"
                class="font"
                @click.prevent="handleClickUpdate(data.item)"
              >
                Modifier
              </a>
            </div>
            <div
              v-if="
                (currentUser && currentUser.isSuper) ||
                  ((computedRegie ||
                    currentUser.type == 'user.commercial' ||
                    currentUser.type == 'user.final') &&
                    data.item.is_you == 0)
              "
            >
              <a
                href="#"
                class="font"
                @click.prevent="handleClickDelete(data.item)"
              >
                Supprimer
              </a>
            </div>
            <div v-if="currentUser && currentUser.isSuper && data.item.type">
              <a
                v-if="data.item.is_active == 1"
                href="#"
                class="font"
                @click.prevent="handleClickDesactiveActive(data.item)"
              >
                Désactiver
              </a>
              <a
                v-if="data.item.is_active == 0"
                href="#"
                class="font"
                @click.prevent="handleClickDesactiveActive(data.item)"
              >
                Activer
              </a>
            </div>
          </div>
        </template>
      </b-table>
      <div class="pagination">
        <div v-if="getUserLoading" class="three-dots-loading loading">
          Chargement en cours
        </div>
        <b-pagination
          v-model="page"
          :total-rows="usersCount"
          :per-page="per_page"
          align="right"
          size="sm"
          prev-text="Précédent"
          next-text="Suivant"
          aria-controls="my-table"
          @change="pagination"
        ></b-pagination>
      </div>
    </div>
    <!-- Delete User Modal -->
    <b-modal
      ref="userContactSecurite"
      id="userContactSecurite"
      no-close-on-backdrop
      title="Mouchard de sécurité"
      :hide-footer="true"
      @hidden="resetModal(true)"
    >
      <b-form-group
        label="Ajouter un ou plusieurs emails de sécurité :"
        label-for="email-client-input"
      >
        <b-input-group class="mb-3" prepend="Email">
          <b-form-input
            type="email"
            required
            v-model="emails.nameOption"
          ></b-form-input>
          <b-input-group-append>
            <b-button size="sm" variant="success" @click="optionsAdd(emails)">
              <font-awesome-icon class="" icon="plus" />
            </b-button> </b-input-group-append
        ></b-input-group>
      </b-form-group>
      <!-- AFFICHER LISTE DES EMAILS -->
      <div
        v-for="(item, index) in computedEmailMouchards"
        :key="index"
        class="mb-2"
      >
        <b-input-group>
          <b-form-input v-model="item.email" readonly></b-form-input>
          <b-input-group-append>
            <b-button size="sm" @click="optionsDelete(item.id)">
              <font-awesome-icon class icon="trash-alt" />
            </b-button> </b-input-group-append
        ></b-input-group>
      </div>
      <div class="message">
        <div v-if="loading" class="three-dots-loading loading">
          Chargement en cours
        </div>
        <div v-if="error" class="error">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ error }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('userContactSecurite')"
          variant="outline-secondary"
          >Fermer</b-button
        >
      </div>
    </b-modal>
    <!-- END Delete User Modal -->
    <!-- Delete User Modal -->
    <b-modal
      ref="userDeleteModal"
      id="userDeleteModal"
      no-close-on-backdrop
      title="Supprimer un utilisateur "
      :hide-footer="true"
      @hidden="resetModal(true)"
    >
      <p>
        Êtes-vous certain de vouloir supprimer l'utilisateur
        <strong class="name">
          {{ userToDelete ? userToDelete.first_name : '' }}
          {{ userToDelete ? userToDelete.last_name : '' }}</strong
        >
        ?
      </p>
      <div class="message">
        <div v-if="loading" class="three-dots-loading loading">
          Chargement en cours
        </div>
        <div v-if="error" class="error">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ error }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('userDeleteModal')"
          variant="outline-secondary"
          >Annuler</b-button
        >
        <b-button variant="success" @click="handleModalSupprimerValider"
          >Valider</b-button
        >
      </div>
    </b-modal>
    <!-- END Delete User Modal -->
    <!-- Desactiver User Modal -->
    <b-modal
      ref="userDesactiveActiveModal"
      id="userDesactiveActiveModal"
      no-close-on-backdrop
      :title="
        userToDesactiver && userToDesactiver.is_active == 1
          ? 'Désactiver un utilisateur'
          : 'Activer un utilisateur'
      "
      :hide-footer="true"
      @hidden="resetModal(true)"
    >
      <div
        v-if="userToDesactiver && userToDesactiver.type == 'user.technicien'"
      >
        <div
          v-if="
            userToDesactiver &&
              userToDesactiver.type == 'user.technicien' &&
              userToDesactiver.is_active == 1
          "
        >
          <p>
            l'utilisateur
            <strong class="name">
              {{ userToDesactiver ? userToDesactiver.first_name : '' }}
              {{ userToDesactiver ? userToDesactiver.last_name : '' }}</strong
            >
            sera désactivé à partir :
          </p>
          <div class="dateDesactiver">
            <b-form-group label="Semaine de début" label-for="start-week-input">
              <b-form-select
                id="start-week-input"
                v-model="userToDesactiver.start_week_id"
              >
                <option></option>
                <option
                  v-for="item in computedWeeks"
                  :key="'start_week_id ' + item.id"
                  :value="item.id"
                  >Semaine {{ item.week_nbr }} (
                  {{ item.first_day_of_week | date }} à
                  {{ item.last_day_of_week | date }} )
                </option>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Semaine de fin" label-for="end-week-input">
              <b-form-select
                id="end-week-input"
                v-model="userToDesactiver.end_week_id"
              >
                <option></option>
                <option
                  v-for="item in computedWeeks"
                  :key="'end_week_id ' + item.id"
                  :value="item.id"
                  >Semaine {{ item.week_nbr }} (
                  {{ item.first_day_of_week | date }} à
                  {{ item.last_day_of_week | date }} )</option
                >
              </b-form-select>
            </b-form-group>
          </div>
        </div>
        <div v-else>
          <p>
            l'utilisateur
            <strong class="name">
              {{ userToDesactiver ? userToDesactiver.first_name : '' }}
              {{ userToDesactiver ? userToDesactiver.last_name : '' }}</strong
            >
            est désactivé à partir de la semaine
            <span v-if="this.userToDesactiver.start_week">
              <strong class="name">
                {{ userToDesactiver.start_week.week_nbr }}
                ( {{ userToDesactiver.start_week.first_day_of_week | date }} à
                {{ userToDesactiver.start_week.last_day_of_week | date }}
                ) </strong
              ><span
                v-if="
                  this.userToDesactiver.start_week &&
                    !this.userToDesactiver.end_week
                "
                >.</span
              >
              <span
                v-if="
                  this.userToDesactiver.start_week ==
                    this.userToDesactiver.end_week
                "
                >.</span
              >
            </span>
            <span
              v-if="
                this.userToDesactiver.end_week &&
                  this.userToDesactiver.end_week.id !=
                    this.userToDesactiver.start_week.id
              "
            >
              à la semaine
              <strong class="name">
                {{ userToDesactiver.end_week.week_nbr }}
                ( {{ userToDesactiver.end_week.first_day_of_week | date }} à
                {{ userToDesactiver.end_week.last_day_of_week | date }}
                ) </strong
              >.
            </span>
          </p>
          <p>
            Êtes-vous certain de vouloir l'activer ?
          </p>
        </div>
      </div>
      <div v-else>
        <div v-if="userToDesactiver && userToDesactiver.is_active == 1">
          <p>
            Êtes-vous certain de vouloir désactiver l'utilisateur
            <strong class="name">
              {{ userToDesactiver ? userToDesactiver.first_name : '' }}
              {{ userToDesactiver ? userToDesactiver.last_name : '' }}</strong
            >
            ?
          </p>
        </div>
        <div v-else>
          <p>
            Êtes-vous certain de vouloir activer l'utilisateur
            <strong class="name">
              {{ userToDesactiver ? userToDesactiver.first_name : '' }}
              {{ userToDesactiver ? userToDesactiver.last_name : '' }}</strong
            >
            ?
          </p>
        </div>
      </div>
      <div class="message">
        <div v-if="loading" class="three-dots-loading loading">
          Chargement en cours
        </div>
        <div v-if="error" class="error">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ error }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('userDesactiveActiveModal')"
          variant="outline-secondary"
          >Annuler</b-button
        >
        <b-button variant="success" @click="handleModalDesactiveActiveValider"
          >Valider</b-button
        >
      </div>
    </b-modal>
    <!-- END Desactiver User Modal -->
    <!-- Update User Modal  -->
    <b-modal
      no-close-on-backdrop
      id="userUpdateModal"
      :title="
        'Modifier ' +
          (userToUpdate ? userToUpdate.first_name_update : '') +
          ' ' +
          (userToUpdate ? userToUpdate.last_name_update : '')
      "
      :hide-footer="true"
      ref="userUpdateModal"
      @hidden="resetModal(true)"
    >
      <form
        ref="updateUserForm"
        @submit.prevent="handleSubmitUpdate"
        v-if="userToUpdate"
      >
        <b-form-group label="Prénom" label-for="firstname-input">
          <b-form-input
            id="user-update-modal-firstname-input"
            v-model="userToUpdate.first_name"
            :disabled="
              computedRegie ||
                currentUser.type == 'user.final' ||
                currentUser.type == 'user.commercial'
            "
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Nom" label-for="lastname-input">
          <b-form-input
            id="user-update-modal-lastname-input"
            v-model="userToUpdate.last_name"
            :disabled="
              computedRegie ||
                currentUser.type == 'user.final' ||
                currentUser.type == 'user.commercial'
            "
          ></b-form-input>
        </b-form-group>
        <!-- <b-form-group
          v-if="currentUser && currentUser.isSuper"
          label="Pipedrive api token"
          label-for="api-token-input"
        >
          <b-form-input
            id="api-token-input"
            v-model="userToUpdate.api_pipe_token"
          ></b-form-input>
        </b-form-group> -->
        <b-form-group
          v-if="currentUser && currentUser.isSuper"
          label="Passerelle"
          label-for="id-pixel-input"
        >
          <b-form-input
            id="id-pixel-input"
            disabled
            v-model="userToUpdate.passerelle_id"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="E-mail" label-for="email-input">
          <b-form-input
            id="user-update-modal-email-input"
            v-model="userToUpdate.email"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          v-if="currentUser && currentUser.isSuper"
          label="Téléphone"
          label-for="phone-input"
        >
          <b-form-input
            id="phone-input"
            v-model="userToUpdate.phone"
            type="tel"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Mot de passe" label-for="password-input">
          <b-form-input
            id="user-update-modal-pasword-input"
            type="password"
            v-model="userToUpdate.password"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Confirmation de mot de passe"
          label-for="password-confirmation-input"
        >
          <b-form-input
            id="user-update-modal-pasword-confirmation-input"
            type="password"
            v-model="userToUpdate.password_confirmation"
            :required="userToUpdate.password ? true : false"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          v-if="currentUser && currentUser.isSuper"
          label="Code postal"
          label-for="user-update-postal-code-input"
        >
          <b-form-input
            id="user-update-postal-code-input"
            type="text"
            v-model="userToUpdate.zipcode"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          v-if="currentUser && currentUser.isSuper"
          label="Type"
          label-for="type-input"
        >
          <b-form-select
            v-if="currentUser.type != 'user.sub-super-admin'"
            id="user-update-modal-type-input"
            v-model="userToUpdate.type"
            @input="handleChangeTypeUpdate"
          >
            <b-form-select-option-group
              v-for="(group, index) in usertypes"
              :key="index"
              :label="group.groupe"
            >
              <b-form-select-option
                v-for="type in group.types"
                :key="'type ' + type.id"
                :value="type.id"
                required
              >
                {{ type.name }}</b-form-select-option
              >
            </b-form-select-option-group>
          </b-form-select>
          <b-form-select
            v-else-if="
              currentUser.type == 'user.sub-super-admin' &&
                userToUpdate.type != 'user.sub-super-admin'
            "
            id="user-update-modal-type-input"
            v-model="userToUpdate.type"
            @input="handleChangeTypeUpdate"
          >
            <b-form-select-option-group
              v-for="(group, index) in usertypes"
              :key="index"
              :label="group.groupe"
            >
              <b-form-select-option
                v-for="type in group.types"
                :key="'type ' + type.id"
                :value="type.id"
                required
              >
                {{ type.name }}</b-form-select-option
              >
            </b-form-select-option-group>
          </b-form-select>
          <b-form-input
            v-else-if="
              currentUser.type == 'user.sub-super-admin' &&
                userToUpdate.type == 'user.sub-super-admin'
            "
            id="user-update-modal-type-input"
            :value="$options.filters.typeFormat(userToUpdate.type)"
            disabled
          ></b-form-input>
        </b-form-group>
        <div class="visibility-export-import">
          <div class="switch" v-if="userToUpdate.type">
            <div>
              <b-form-group label="Export Excel"></b-form-group>
            </div>
            <div class="etat-switch">
              <b-form-checkbox
                v-if="userToUpdate.type == 'user.sub-super-admin'"
                switch
                v-model="checkedExport"
                :disabled="userToUpdate.type == 'user.sub-super-admin'"
              ></b-form-checkbox>
              <b-form-checkbox
                v-else
                switch
                v-model="userToUpdate.has_export_excel"
                :value="1"
                :unchecked-value="0"
              ></b-form-checkbox>
            </div>
          </div>
          <div class="switch" v-if="userToUpdate.type">
            <div>
              <b-form-group label="Import Excel"></b-form-group>
            </div>
            <div class="etat-switch">
              <b-form-checkbox
                switch
                v-model="userToUpdate.has_import_excel"
                :value="1"
                :unchecked-value="0"
              ></b-form-checkbox>
            </div>
          </div>
        </div>
        <b-form-group
          v-if="
            computedRegie &&
              userToUpdate.type != 'user.admin' &&
              currentUser.type != 'user.commercial' &&
              currentUser.type != 'user.final'
          "
          label="Type"
          label-for="type-input"
        >
          <b-form-select
            v-model="userToUpdate.typeRegie"
            :disabled="computedRegie"
          >
            <option v-for="type in usertypes" :key="type.text">{{
              type.text
            }}</option>
          </b-form-select>
        </b-form-group>

        <b-form-group
          v-if="
            userToUpdate.type == 'user.final' &&
              currentUser.type != 'user.final' &&
              currentUser.type != 'user.commercial'
          "
          label="Supérieur"
          label-for="superieur-input"
        >
          <b-form-select
            v-if="
              listCommercailsUser &&
                listCommercailsUser.data &&
                listCommercailsUser.data.length
            "
            id="user-update-modal-superieur-input"
            v-model="userToUpdate.superior_id"
            required
          >
            <option :value="null" disabled>
              -- Sélectionner un Supérieur --
            </option>
            <option
              v-for="item in listCommercailsUserUpdate.data"
              :key="'superior_id ' + item.id"
              :value="item.id"
            >
              {{ item.first_name }} {{ item.last_name }}
            </option>
          </b-form-select>
          <b-form-select
            v-else
            id="user-update-modal-superieur-input"
            v-model="userToUpdate.superior_id"
            required
          >
            <option :value="null" disabled>
              -- Aucun Supérieur --
            </option>
            <option
              v-for="item in listCommercailsUserUpdate.data"
              :key="'superior_id ' + item.id"
              :value="item.id"
            >
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          v-if="userToUpdate.type == 'user.technicien'"
          label="Co-amo"
          label-for="co-techniciens-input"
        >
          <b-form-select
            id="co-techniciens-input"
            v-model="userToUpdate.co_technician_id"
          >
            <option
              v-for="item in [
                { id: null, label: '' },
                ...computedListTechniciens
              ]"
              :key="'co-poseur ' + item.id"
              :value="item.id"
            >
              {{ item.full_name }}
            </option>
          </b-form-select>
        </b-form-group>
        <!-- <b-form-group
          v-if="userToUpdate.type == 'user.technicien'"
          label="Techniciens dans pipedrive"
          label-for="technicien-input"
        >
          <b-form-select
            id="technicien-input"
            v-model="userToUpdate.technician_id_pipe"
          >
            <option
              v-for="item in computedTechnicianspipedrive"
              :key="'technician_pipe-' + item.id_tech_pipe"
              :value="item.id_tech_pipe"
            >
              {{ item.label }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          v-if="userToUpdate.type == 'user.commercial.ite'"
          label="Commerciaux dans Pipedrive"
          label-for="commercial-input"
        >
          <b-form-select
            id="commercial-input"
            v-model="userToUpdate.commercial_ite_id_pipe"
          >
            <option
              v-for="item in computedCommerciauxpipedrive"
              :key="'commercial_ite_pipe ' + item.id_comm_ite_pipe"
              :value="item.id_comm_ite_pipe"
            >
              {{ item.label }}
            </option>
          </b-form-select>
        </b-form-group> -->
        <b-form-group
          v-if="
            userToUpdate.type == 'user.commercial' &&
              currentUser.type != 'user.commercial'
          "
          label="Commission 101"
          label-for="commission101-input"
        >
          <b-form-input
            type="number"
            id="commission101-input"
            v-model="userToUpdate.coeff_101"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          v-if="
            userToUpdate.type == 'user.commercial' &&
              currentUser.type != 'user.commercial'
          "
          label="Commission 102"
          label-for="commission102-input"
        >
          <b-form-input
            type="number"
            id="commission102-input"
            v-model="userToUpdate.coeff_102"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          v-if="
            userToUpdate.type == 'user.commercial' &&
              currentUser.type != 'user.commercial'
          "
          label="Commission 103"
          label-for="commission103-input"
        >
          <b-form-input
            type="number"
            id="commission103-input"
            v-model="userToUpdate.coeff_103"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          v-if="
            currentUser &&
              currentUser.isSuper &&
              (userToUpdate.type == 'user.commercial' ||
                userToUpdate.type == 'user.final')
          "
          label="Proprietaire"
          label-for="owner-input"
        >
          <b-form-select id="owner-input" v-model="userToUpdate.owner_id">
            <option></option>
            <option
              v-for="item in property"
              :key="'property' + item.id"
              :value="item.id"
              >{{ item.full_name }}</option
            >
          </b-form-select>
        </b-form-group>
        <div v-if="currentUser && currentUser.isSuper" class="show-super">
          <div>
            <label class="mt-2">
              Couleur planning pose
            </label>
            <color-picker
              class="color-pose ml-4 mt-1"
              v-model="userToUpdate.color_pose"
              @change="changeColor($event)"
            ></color-picker>
          </div>
          <div>
            <div class="switch-interne">
              <div>
                <b-form-group label="Interne"></b-form-group>
              </div>
              <div class="etat-switch">
                <b-form-checkbox
                  switch
                  v-model="userToUpdate.can_edit_fiscal"
                  :value="1"
                  :unchecked-value="0"
                ></b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
        <!-- <b-form-group
          v-if="
            currentUser &&
              currentUser.isSuper &&
              (userToUpdate.type == 'user.admin' ||
                userToUpdate.type == 'user.agent' ||
                userToUpdate.type == 'user.resp.plan' ||
                userToUpdate.type == 'user.administration')
          "
          label="Utilisateur pipedrive "
          label-for="user-pip-input"
        >
          <b-form-select
            id="user-pip-input"
            v-model="userToUpdate.pipe_user_id"
          >
            <option
              v-for="item in [
                { id: null, label: '' },
                ...computedUtilisateurPipedrive
              ]"
              :key="'pipe_user_id ' + item.id"
              :value="item.id"
            >
              {{ item.name }}</option
            >
          </b-form-select>
        </b-form-group> -->

        <div class="message mt-3">
          <div v-if="loading" class="three-dots-loading loading">
            Chargement en cours
          </div>
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('userUpdateModal')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Valider
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- END Update User Modal  -->
    <!-- Export User Modal  -->
    <b-modal
      id="exportUserModal"
      :title="'Export des utilisateurs'"
      no-close-on-backdrop
      :hide-footer="true"
      ref="exportUserModal"
      @hidden="resetModal(true)"
    >
      <form @submit.prevent="handleExportUser">
        <b-row class="row">
          <b-col class="col1">
            <b-form-group label="Type" label-for="type-input">
              <b-form-select id="type-input" v-model="userToExport.type">
                <b-form-select-option :value="null">Tous </b-form-select-option>
                <b-form-select-option-group
                  v-for="(group, index) in usertypes"
                  :key="index"
                  :label="group.groupe"
                >
                  <b-form-select-option
                    v-for="type in group.types"
                    :key="'types' + type.id"
                    :value="type.id"
                    required
                  >
                    {{ type.name }}</b-form-select-option
                  >
                </b-form-select-option-group>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Equipe">
              <Multiselect
                v-model="userToExport.team_type"
                label="name"
                track-by="id"
                :showLabels="false"
                :closeOnSelect="true"
                open-direction="bottom"
                :allow-empty="false"
                :options="computedExportUser || []"
                :searchable="true"
                :internal-search="true"
                :max-height="600"
                placeholder="Rechercher Equipe"
                required
              >
                <span slot="noResult">Aucun option trouvé.</span>
                <span slot="noOptions">Aucun option trouvé.</span>
              </Multiselect>
            </b-form-group>
            <div class="active-desactive-export">
              <b-form-checkbox
                :value="1"
                :unchecked-value="1"
                v-model="userToExport.active"
              >
              </b-form-checkbox>

              <span>Activé</span>
              <b-form-checkbox
                :value="0"
                :unchecked-value="0"
                v-model="userToExport.active"
                class="ml-2"
              >
              </b-form-checkbox>

              <span>Désactivé</span>
            </div>
          </b-col>

          <b-col class="col2">
            Liste de colonnes :
            <b-form-group>
              <div>
                <b-form-checkbox
                  v-for="(item, index) in attributes"
                  :key="index"
                  :disabled="isDisabled(item)"
                  :value="item"
                  :unchecked-value="false"
                  v-model="userToExport.columns[index]"
                >
                  <div>{{ item }}</div>
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="message">
          <div v-if="loading" class="three-dots-loading loading">
            Chargement en cours
          </div>
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
          <span class="error" v-if="requiredColumns == true"
            >Le champ liste de colonnes est obligatoire.</span
          >
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('exportUserModal')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Valider
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- END Export User Modal  -->
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'
import * as moment from 'moment'
moment.locale('fr')
export default {
  components: {
    Multiselect
  },
  data() {
    return {
      userToAdd: {
        first_name: null,
        last_name: null,
        last_name_update: null,
        first_name_update: null,
        email: null,
        api_pipe_token: null,
        password: null,
        password_confirmation: null,
        type: '',
        typeRegie: '',
        superior_id: null,
        coeff_101: null,
        coeff_102: null,
        coeff_103: null,
        owner_id: null,
        co_technician_id: null,
        technician_id_pipe: null,
        commercial_ite_id_pipe: null,
        pipe_user_id: null,
        phone: null,
        zipcode: null,
        has_export_excel: 0,
        has_import_excel: 0,
        can_edit_fiscal: 0,
        passerelle_id: null,
        color_pose: null
      },
      fields: [
        { key: 'first_name', label: 'Nom', sortable: true },
        { key: 'email', label: 'E-mail', sortable: true },
        { key: 'type', label: 'Type', sortable: false },
        { key: 'team_name', label: 'Equipe', sortable: false },
        { key: 'created_at', label: 'Date de création', sortable: false },
        { key: 'actions', label: '', sortable: false }
      ],
      page: 1,
      per_page: 10,
      active: 1,
      sort_by: 'first_name',
      sort_by_desc: 'first_name',
      sortDesc: false,
      start_week_id: null,
      end_week_id: null,
      full_name: '',
      userToUpdate: null,
      userToDelete: null,
      userToDesactiver: null,
      loading: false,
      initLoading: true,
      error: null,
      checkedExport: true,
      userToExport: {
        type: null,
        team_type: null,
        active: null,
        columns: []
      },
      teamTypesStatique: [
        {
          id: 'with-team',
          name: 'Tous les equipes'
        },
        {
          id: 'without-team',
          name: 'Sans equipes'
        },
        {
          id: 'with-without-team',
          name: 'Avec ou Sans equipes'
        }
      ],
      attributes: [
        'ID',
        'PASSERELLE ID',
        'PRÉNOM',
        'NOM',
        'EMAIL',
        'ROLE',
        "NOM DE L'ÉQUIPE",
        "TYPE D'ÉQUIPE"
      ],
      requiredColumns: false,
      emails: {
        nameOption: null,
        selectedOption: []
      }
    }
  },
  methods: {
    ...mapActions([
      'getUsers',
      'updateUser',
      'getUsersType',
      'getlistCommercailsUser',
      'getlistCommercailsUserUpdate',
      'deleteUser',
      'getTechnicianspipedrive',
      'getCommerciauxPipedrive',
      'getUsersPipedrive',
      'activerDesactiverUser',
      'getlistWeeks',
      'getTechniciansPipeUpdate',
      'getCommerciauxPipeUpdate',
      'fetchUsersTechniciens',
      'fetchProprieteRegie',
      'getteamsTypes',
      'exportUser',
      'getAllMouchards',
      'createMouchard',
      'deleteMouchard'
    ]),
    handlSecuriteParEmail() {},
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(email)
    },
    optionsDelete(id) {
      this.loading = true
      const response = this.deleteMouchard(id)
      if (response && response.error) {
        this.error = response.error
      }
      setTimeout(() => {
        this.loading = false
      }, 800)
    },
    optionsAdd(event) {
      this.loading = true
      this.error = null
      if (this.validateEmail(event.nameOption)) {
        this.createMouchard({ email: event.nameOption })
          .then(response => {
            if (response && response.error) {
              this.error =
                response.error && response.error.length
                  ? response.error[0]
                  : response.error
            }
            if (response.success) {
              this.emails.nameOption = null
            }
          })
          .catch()
      } else {
        this.error = "L'email n'est pas valide"
      }
      setTimeout(() => {
        this.loading = false
      }, 800)
    },
    async exportClickUser() {
      await this.getteamsTypes()
    },
    isDisabled(item) {
      if (
        this.userToExport.team_type &&
        this.userToExport.team_type.id == 'without-team' &&
        (item == "NOM DE L'ÉQUIPE" || item == "TYPE D'ÉQUIPE")
      ) {
        return true
      }
      return false
    },
    async handleExportUser() {
      let ObjectExportUser = {}
      let columnsNew = this.userToExport.columns.filter(item => item != false)
      if (this.userToExport.columns && columnsNew.length > 0) {
        this.requiredColumns = false
        ObjectExportUser.columns = columnsNew
        if (this.userToExport.active) {
          ObjectExportUser.active = this.userToExport.active
        }
        if (this.userToExport.team_type) {
          ObjectExportUser.team_type = this.userToExport.team_type.id
        }
        if (this.userToExport.type) {
          ObjectExportUser.type = this.userToExport.type
        }

        this.loading = true
        this.error = null
        const response = await this.exportUser({ payload: ObjectExportUser })
        this.loading = false
        if (!response.success) {
          this.error = response.error
        } else {
          this.hideModal('exportUserModal')
        }
      } else {
        this.requiredColumns = true
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },
    handleClickDelete(data_item) {
      this.userToDelete = data_item
      this.$refs['userDeleteModal'].show()
    },
    handleClickDesactiveActive(data_item) {
      this.userToDesactiver = { ...data_item }
      if (this.userToDesactiver.end_week_id) {
        this.weeks.forEach(week => {
          if (week.id == this.userToDesactiver.end_week_id) {
            this.userToDesactiver.end_week = week
          }
        })
      }
      this.weeks.forEach(week => {
        if (week.id == this.userToDesactiver.start_week_id) {
          this.userToDesactiver.start_week = week
        }
      })
      this.$refs['userDesactiveActiveModal'].show()
    },
    handleClickUpdate(data_item) {
      this.userToUpdate = { ...data_item }
      if (this.userToUpdate && this.userToUpdate.type == 'user.final') {
        this.getlistCommercailsUserUpdate(this.userToUpdate.id)
      }
      this.$refs['userUpdateModal'].show()
      this.userToUpdate.last_name_update = this.userToUpdate.last_name
      this.userToUpdate.first_name_update = this.userToUpdate.first_name
      if (
        this.userToUpdate.type == 'user.final' ||
        this.userToUpdate.type == 'user.commercial'
      ) {
        this.fetchProprieteRegie({
          exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
        })
        if (this.computedRegie) {
          if (this.userToUpdate.type == 'user.final') {
            this.userToUpdate.typeRegie = 'Utilisateur Final'
          } else {
            this.userToUpdate.typeRegie = 'Commercial'
          }
        }
      }
    },
    handleChangeTypeUpdate() {
      this.userToUpdate.has_export_excel = 0
      this.userToUpdate.has_import_excel = 0
      this.userToUpdate.can_edit_fiscal = 0
      if (this.userToUpdate && this.userToUpdate.type == 'user.final') {
        this.getlistCommercailsUserUpdate(this.userToUpdate.id)
      }
    },
    async handleModalSupprimerValider() {
      this.loading = true
      this.error = null
      const response = await this.deleteUser(this.userToDelete.id)
      if (response.success) {
        this.loading = false
        this.hideModal('userDeleteModal')
        this.getlistCommercailsUser()
        this.getTechnicianspipedrive()
        this.getUsersPipedrive()
        this.fetchProprieteRegie({
          exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
        })
        this.fetchUsersTechniciens()
        this.getCommerciauxPipedrive()
        if (!this.sortDesc) {
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        } else {
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by_desc: this.sort_by_desc,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        }
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    async handleModalDesactiveActiveValider() {
      this.loading = true
      this.error = null
      const response = await this.activerDesactiverUser(this.userToDesactiver)
      if (response.success) {
        this.loading = false
        this.hideModal('userDesactiveActiveModal')
        if (!this.sortDesc) {
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        } else {
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by_desc: this.sort_by_desc,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        }
        this.userToDesactiver = null
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    resetModal(updatePipeUsers = false) {
      this.userToAdd = {
        first_name: null,
        last_name: null,
        last_name_update: null,
        first_name_update: null,
        email: null,
        api_pipe_token: null,
        password: null,
        password_confirmation: null,
        type: '',
        typeRegie: '',
        superior_id: null,
        coeff_101: null,
        coeff_102: null,
        coeff_103: null,
        owner_id: null,
        co_technician_id: null,
        technician_id_pipe: null,
        commercial_ite_id_pipe: null,
        pipe_user_id: null,
        phone: null,
        zipcode: null,
        has_export_excel: 0,
        has_import_excel: 0,
        can_edit_fiscal: 0,
        passerelle_id: null,
        color_pose: null
      }
      this.fields = [
        { key: 'first_name', label: 'Nom', sortable: true },
        { key: 'email', label: 'E-mail', sortable: true },
        { key: 'type', label: 'Type', sortable: false },
        { key: 'team_name', label: 'Equipe', sortable: false },
        { key: 'created_at', label: 'Date de création', sortable: false },
        { key: 'actions', label: '', sortable: false }
      ]
      this.start_week_id = null
      this.end_week_id = null
      this.userToDelete = null
      this.userToDesactiver = null
      this.userToUpdate = null
      this.loading = false
      this.error = null
      if (updatePipeUsers) {
        this.getUsersPipedrive()
      }
      this.emails = {
        nameOption: null
      }
    },
    changeColor(color) {
      this.color_pose = color
    },
    async handleSubmitUpdate() {
      this.loading = true
      this.error = null
      if (this.userToUpdate.password) {
        if (
          this.userToUpdate.password != this.userToUpdate.password_confirmation
        ) {
          this.loading = false
          this.error = [
            'le mot de passe et la confirmation du mot de passe doivent être égaux'
          ]
          return
        }
        if (this.userToUpdate.password.length < 6) {
          this.loading = false
          this.error = ['le mot de passe doit contenir au moins 6 caractères']
          return
        }
      }
      const response = await this.updateUser(this.userToUpdate)
      if (response.success) {
        this.loading = false
        this.getTechnicianspipedrive()
        this.getUsersPipedrive()
        this.fetchProprieteRegie({
          exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
        })
        this.fetchUsersTechniciens()
        this.getCommerciauxPipedrive()
        this.hideModal('userUpdateModal')
        if (!this.sortDesc) {
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        } else {
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by_desc: this.sort_by_desc,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        }
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    SortUsers() {
      if (!this.sortDesc) {
        this.getUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name,
          active: this.active
        })
      } else {
        this.getUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name,
          active: this.active
        })
      }
    },
    handlClickContactSecurite() {
      this.loading = true
      this.getAllMouchards()
      this.$refs['userContactSecurite'].show()
      setTimeout(() => {
        this.loading = false
      }, 800)
    },
    handlClickActive(state) {
      this.active = state
      if (this.active === 1) {
        this.page = 1
        if (!this.sortDesc) {
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        } else {
          this.active = state
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by_desc: this.sort_by_desc,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        }
      } else {
        this.page = 1
        if (!this.sortDesc) {
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        } else {
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by_desc: this.sort_by_desc,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        }
      }
    },
    filter() {
      this.page = 1
      if (!this.sortDesc) {
        this.getUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      } else {
        this.getUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      }
    },
    sort(sort) {
      this.sort_by = sort.sortBy
      this.sort_by_desc = sort.sortBy
      if (!sort.sortDesc) {
        this.getUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      } else {
        this.getUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      }
    },
    pagination(paginate) {
      this.page = paginate
      if (!this.sortDesc) {
        this.getUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      } else {
        this.getUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      }
    }
  },
  computed: {
    ...mapGetters([
      'users',
      'usertypes',
      'listCommercailsUser',
      'listCommercailsUserUpdate',
      'technicianspipedrive',
      'commerciauxpipedrive',
      'usersPipedriveNoSelectionne',
      'weeks',
      'usersCount',
      'getUserLoading',
      'getProjectsUsersTechniciens',
      'property',
      'currentUser',
      'teamsTypes',
      'getMouchards'
    ]),
    computedEmailMouchards: function() {
      return this.getMouchards
    },
    computedExportUser: function() {
      return [
        {
          id: 'with-team',
          name: 'Tous les equipes'
        },
        {
          id: 'without-team',
          name: 'Sans equipes'
        },
        {
          id: 'with-without-team',
          name: 'Avec ou Sans equipes'
        },
        ...this.teamsTypes
      ]
    },
    computedWeeks: function() {
      const currentWeek = moment().week()
      return this.weeks.filter(week => {
        if (
          week.id >= currentWeek &&
          week.last_day_of_week >= week.first_day_of_week
        ) {
          return week
        }
      })
    },
    computedTechnicianspipedrive: function() {
      if (
        this.userToUpdate &&
        this.userToUpdate.technicianPipe &&
        this.userToUpdate.technicianPipe.data
      ) {
        return [
          this.userToUpdate.technicianPipe.data,
          ...this.technicianspipedrive
        ]
      }
      return this.technicianspipedrive
    },
    computedCommerciauxpipedrive: function() {
      if (
        this.userToUpdate &&
        this.userToUpdate.commercialItePipe &&
        this.userToUpdate.commercialItePipe.data
      ) {
        return [
          this.userToUpdate.commercialItePipe.data,
          ...this.commerciauxpipedrive
        ]
      }
      return this.commerciauxpipedrive
    },
    computedUtilisateurPipedrive: function() {
      if (
        this.userToUpdate &&
        this.userToUpdate.userPipe &&
        this.userToUpdate.userPipe.data
      ) {
        return [
          this.userToUpdate.userPipe.data,
          ...this.usersPipedriveNoSelectionne
        ]
      }
      return this.usersPipedriveNoSelectionne
    },
    computedListTechniciens: function() {
      return this.getProjectsUsersTechniciens.filter(
        t => t.id != this.userToUpdate.id
      )
    },
    computedRegie: function() {
      return (
        this.currentUser.type == 'user.admin' &&
        this.currentUser.details &&
        this.currentUser.details.team &&
        this.currentUser.details.team.data &&
        this.currentUser.details.team.data.type === 'regie'
      )
    }
  },
  async mounted() {
    await this.getUsers({
      page: this.page,
      per_page: this.per_page,
      sort_by: this.sort_by,
      active: this.active,
      exclude: ['team.manager', 'team.depots', 'visibleTeams']
    })
    this.initLoading = false
    this.getlistWeeks()
    this.getUsersType()
    this.getlistCommercailsUser()
    this.getTechnicianspipedrive()
    this.getCommerciauxPipedrive()
    this.getTechniciansPipeUpdate()
    this.getCommerciauxPipeUpdate()
    this.getUsersPipedrive()
    this.fetchUsersTechniciens(), this.getAllMouchards()
  },
  filters: {
    typeFormat: value => {
      switch (value) {
        case 'user.admin':
          return 'Utilisateur Admin'
        case 'user.agent':
          return 'Agent'
        case 'user.resp.plan':
          return 'Responsable Planning'
        case 'user.supervisor':
          return 'Régie Superviseur'
        case 'user.commercial':
          return 'Régie Commercial'
        case 'user.final':
          return 'Régie Utilisateur Final'
        case 'user.technicien':
          return 'Filiale Poseur'
        case 'user.administration':
          return 'CERTIGAIA Administration'
        case 'user.client':
          return 'Client'
        case 'user.commercial.ite':
          return 'AMO'
        case 'user.sub-super-admin':
          return 'Sous Super Admin'
        case 'user.ingenieur':
          return 'Ingénieur'
        case 'user.visiteur.tech':
          return 'Visiteur Technique'
        case 'user.tech.travaux':
          return 'Technicien Travaux'
        default:
          return ''
      }
    },
    date: function(value) {
      if (!value) return ''
      value = moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      return value
    },
    FormatType: value => {
      switch (value) {
        case 'ge':
          return 'CERTIGAIA'
        default:
          return value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.users-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .header-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 11px;
    .search {
      display: flex;
      align-items: center;
      white-space: nowrap;
      // width: 100%;
    }
  }
  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .activer-desactiver-user {
    display: flex;
    align-items: center;
    .button-activer {
      font-size: 13px;
      padding: 3.375px 5.75px;
      &.border-show {
        box-shadow: unset;
      }
    }
    .button-desactiver {
      margin-left: 8px;
      font-size: 13px;
      padding: 3.375px 5.75px;
    }
    .btn-outline-secondary:not(:disabled):not(.disabled):active,
    .btn-outline-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-outline-secondary.dropdown-toggle {
      color: #212529;
      background-color: #6c757d2e;
      border-color: #6c757d91;
    }
    .btn-outline-secondary:hover {
      color: #212529;
      background-color: #6c757d2e;
      border-color: #6c757d91;
    }
    .btn-outline-secondary {
      color: #212529;
      border-color: #6c7d7d60;
    }
  }
  .input-search {
    border: 1px solid #dadada;
    outline: none;
    font-size: 16px;
    height: 40px;
    background: #fff;
    padding-left: 10px;
  }
  .searchIcon {
    padding: 10px;
    border: 1px solid #d6d8db;
    font-size: 16px;
    background: #d6d8da17;
  }
  .init-loading {
    padding: 15px 5px;
  }
  .content-tab-users {
    padding-top: 4px;
    .sortloading {
      padding-bottom: 20px;
    }
    table {
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;
      color: #212529;
      border: 0;
      border-top: 1px solid #dee2e6;
      border-bottom: 1px solid #b9babb;
      margin-top: -8px;
      font-size: 12px;

      tbody {
        .font-div,
        .font-p {
          font-size: 15px;
          margin-top: 0;
          margin-bottom: 0;
          &.font-div {
            text-transform: capitalize;
          }
        }
        .actions {
          text-align: left;
          margin-left: 7px;
          .font {
            font-weight: normal;
            text-transform: capitalize;
            font-size: 14px;
            color: #007bff;
          }
        }
        .icon-exclamation {
          text-decoration: line-through;
          text-decoration-color: #e73e01;
        }
        .name {
          text-transform: capitalize;
        }
        .equipe {
          font-weight: normal;
          text-transform: capitalize;
          font-size: 12px;
          color: #317ae2;
        }
        .type {
          font-weight: normal;
          text-transform: capitalize;
          font-size: 12px;
          color: #317ae2;
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      width: 100%;
      .loading {
        width: 50%;
      }
    }
  }
  @media screen and (max-width: 720px) {
    .header-user {
      display: block;
      .search {
        input {
          width: 100%;
        }
      }
      .activer-desactiver-user {
        margin-top: 10px;
      }
    }
    .content-tab-users {
      width: 100%;
      overflow: auto;
    }
  }
}
</style>
<style lang="scss">
.content-tab-users {
  .table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left,
  .table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
    background-position: left calc(3.3rem / 2) center;
    padding-left: calc(1.3rem + -0.35em);
  }
  .table thead th {
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
  }
  .table-sm th,
  .table-sm td {
    vertical-align: middle;
    border: 1px solid #dee2e6;
  }
}
#userDeleteModal {
  .name {
    text-transform: capitalize;
  }
  .message {
    .error {
      padding: 25px;
      color: #ff5722;
    }
  }
}
#userDesactiveActiveModal {
  .name {
    text-transform: capitalize;
  }
  .message {
    .error {
      padding: 25px;
      color: #ff5722;
    }
  }
}
#addUserModal,
#userUpdateModal {
  form {
    .form-group {
      .custom-select {
        optgroup {
          text-transform: uppercase;
          option {
            text-transform: none;
          }
        }
      }
    }
    .form-actions {
      padding-top: 20px;
    }
    .color-block {
      width: 32px;
      height: 28px;
      cursor: pointer;
      position: relative;
      border: 1px solid rgb(206, 212, 218);
    }
  }
}
#exportUserModal {
  .active-desactive-export {
    flex-direction: row;
    align-items: center;
    display: flex;
  }
}
#userUpdateModal {
  .modal-title {
    text-transform: capitalize;
  }
  .visibility-export-import {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: -8px;
    .switch {
      display: flex;
      .etat-switch {
        margin-left: 25px;
        margin-top: -2px;
      }
      .custom-switch
        .custom-control-input:disabled:checked
        ~ .custom-control-label::before {
        color: #fff;
        border-color: #08a742;
        background-color: #08a742;
      }
    }
  }
  .show-super {
    display: flex;
    align-items: center;
    .switch-interne {
      display: flex;
      margin-bottom: -24px;
      margin-left: 104px;
      .etat-switch {
        margin-left: 25px;
        margin-top: -2px;
      }
      .custom-switch
        .custom-control-input:disabled:checked
        ~ .custom-control-label::before {
        color: #fff;
        border-color: #08a742;
        background-color: #08a742;
      }
    }
  }
}
</style>
